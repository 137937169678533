<template>
  <div class="show-review-modal">
    <b-modal
      modal-class="show-review-modal"
      id="show-review"
      :title="$t('reviews.show_review')"
      centered
      cancel-variant="outline-primary"
      :cancel-title="$t('reviews.cancel')"
    >
      <h3>
        {{ userName }}
      </h3>
      <p>{{ text }}</p>
      <div v-if="stars">
        {{ $t("reviews.rating") }}:
        <span v-for="star in parseInt(stars)" :key="star">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21.287 8.27657L15.3362 7.41173L12.6761 2.01876C12.6034 1.8711 12.4839 1.75157 12.3362 1.67891C11.9659 1.4961 11.5159 1.64845 11.3307 2.01876L8.67059 7.41173L2.71981 8.27657C2.55574 8.30001 2.40574 8.37735 2.2909 8.49454C2.15206 8.63724 2.07555 8.82923 2.07819 9.02831C2.08083 9.22739 2.16239 9.41728 2.30496 9.55626L6.61043 13.7539L5.59324 19.6813C5.56939 19.8191 5.58465 19.961 5.63729 20.0906C5.68993 20.2203 5.77784 20.3326 5.89106 20.4148C6.00428 20.497 6.13828 20.5459 6.27785 20.5558C6.41743 20.5658 6.557 20.5364 6.68074 20.4711L12.0034 17.6727L17.3261 20.4711C17.4714 20.5484 17.6401 20.5742 17.8018 20.5461C18.2097 20.4758 18.4839 20.0891 18.4136 19.6813L17.3964 13.7539L21.7018 9.55626C21.819 9.44142 21.8964 9.29142 21.9198 9.12735C21.9831 8.7172 21.6972 8.33751 21.287 8.27657Z"
              :fill="chooseColor(stars)"
            />
          </svg>
        </span>

        <!-- <svg
          v-if="stars - parseInt(stars)"
          :fill="chooseColor(stars)"
          width="24"
          height="24"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.383 12.699c-0.1-0.303-0.381-0.519-0.713-0.519-0 0-0 0-0 0h-9.898l-3.059-9.412c-0.1-0.303-0.381-0.518-0.712-0.518-0.083 0-0.163 0.014-0.238 0.039l0.005-0.002c-0.226 0.078-0.399 0.256-0.468 0.48l-0.001 0.005-0.012-0.004-3.059 9.412h-9.897c-0.414 0-0.749 0.336-0.749 0.75 0 0.248 0.121 0.469 0.307 0.605l0.002 0.001 8.007 5.818-3.059 9.412c-0.023 0.069-0.037 0.149-0.037 0.232 0 0.414 0.336 0.75 0.75 0.75 0.165 0 0.318-0.053 0.442-0.144l-0.002 0.001 8.008-5.819 8.006 5.819c0.122 0.090 0.275 0.144 0.441 0.144 0.414 0 0.75-0.336 0.75-0.75 0-0.083-0.014-0.164-0.039-0.239l0.002 0.005-3.059-9.412 8.010-5.818c0.188-0.138 0.308-0.357 0.308-0.605 0-0.083-0.014-0.163-0.038-0.238l0.002 0.005zM20.779 18.461c-0.188 0.138-0.309 0.358-0.309 0.607 0 0.083 0.014 0.163 0.039 0.238l-0.002-0.005 2.514 7.736-6.581-4.783c-0.116-0.080-0.259-0.128-0.414-0.128-0.009 0-0.018 0-0.028 0l0.001-0v-16.701l2.514 7.737c0.1 0.303 0.381 0.519 0.713 0.519 0 0 0 0 0 0h8.135z"
          ></path>
        </svg> -->
        <svg
          v-if="stars - parseInt(stars)"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.287 8.27657L15.3362 7.41173L12.6761 2.01876C12.6034 1.8711 12.4839 1.75157 12.3362 1.67891C11.9659 1.4961 11.5159 1.64845 11.3307 2.01876L8.67059 7.41173L2.71981 8.27657C2.55574 8.30001 2.40574 8.37735 2.2909 8.49454C2.15206 8.63724 2.07555 8.82923 2.07819 9.02831C2.08083 9.22739 2.16239 9.41728 2.30496 9.55626L6.61043 13.7539L5.59324 19.6813C5.56939 19.8191 5.58465 19.961 5.63729 20.0906C5.68993 20.2203 5.77784 20.3326 5.89106 20.4148C6.00428 20.497 6.13828 20.5459 6.27785 20.5558C6.41743 20.5658 6.557 20.5364 6.68074 20.4711L12.0034 17.6727L17.3261 20.4711C17.4714 20.5484 17.6401 20.5742 17.8018 20.5461C18.2097 20.4758 18.4839 20.0891 18.4136 19.6813L17.3964 13.7539L21.7018 9.55626C21.819 9.44142 21.8964 9.29142 21.9198 9.12735C21.9831 8.7172 21.6972 8.33751 21.287 8.27657Z"
            fill="#E9E9E9"
          />
          <g clip-path="url(#clip0_163_2041)">
            <path
              d="M21.287 8.27657L15.3362 7.41173L12.6761 2.01876C12.6034 1.8711 12.4839 1.75157 12.3362 1.67891C11.9659 1.4961 11.5159 1.64845 11.3307 2.01876L8.67059 7.41173L2.71981 8.27657C2.55574 8.30001 2.40574 8.37735 2.2909 8.49454C2.15206 8.63724 2.07555 8.82923 2.07819 9.02831C2.08083 9.22739 2.16239 9.41728 2.30496 9.55626L6.61043 13.7539L5.59324 19.6813C5.56939 19.8191 5.58465 19.961 5.63729 20.0906C5.68993 20.2203 5.77784 20.3326 5.89106 20.4148C6.00428 20.497 6.13828 20.5459 6.27785 20.5558C6.41743 20.5658 6.557 20.5364 6.68074 20.4711L12.0034 17.6727L17.3261 20.4711C17.4714 20.5484 17.6401 20.5742 17.8018 20.5461C18.2097 20.4758 18.4839 20.0891 18.4136 19.6813L17.3964 13.7539L21.7018 9.55626C21.819 9.44142 21.8964 9.29142 21.9198 9.12735C21.9831 8.7172 21.6972 8.33751 21.287 8.27657Z"
              :fill="chooseColor(stars)"
            />
          </g>
          <defs>
            <clipPath id="clip0_163_2041">
              <rect
                width="10"
                height="19"
                fill="white"
                transform="translate(2 2)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
export default {
  name: "ShowReview",
  components: {
    BModal,
  },
  props: ["text", "userName", "stars", "id"],
  methods: {
    chooseColor(stars) {
      if (stars <= 2) {
        return "#F55454";
      } else if (stars > 2 && stars <= 3) {
        return "#FFA451";
      } else if (stars > 3) {
        return "#FBBF25";
      }
    }, 
    approveReview() {
      let formData = new FormData();
      formData.append("is_approved", 1);

      this.$http
        .post(`admin/reviews/${this.id}?_method=PUT`, formData)
        .then((response) => {
          this.$helpers.makeToast(
            "success",
            response.data.message,
            response.data.message
          );
          this.$emit("update-table", true);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    rejectReview() {
      this.$http
        .delete(`admin/reviews/${this.id}`)
        .then(() => {
          this.$emit("update-table", true);
          this.$helpers.makeToast(
            "success",
            this.$t("reviews.reject_review_title_successfully"),
            this.$t("reviews.reject_review_body_successfully")
          );
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss" src="./_ShowReviewModel.scss"></style>
