<template>
  <Loading v-if="isLoading" />
  <div v-else class="reviews-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("reviews.reviews") }}</h1>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'id'" class="text-nowrap">
            {{ $t("reviews.id") }}
          </span>
          <span v-else-if="props.column.label === 'client'" class="text-nowrap">
            {{ $t("reviews.client") }}
          </span>
          <span
            v-else-if="props.column.label === 'product'"
            class="text-nowrap"
          >
            {{ $t("reviews.product_name") }}
          </span>
          <span v-else-if="props.column.label === 'vendor'" class="text-nowrap">
            {{ $t("reviews.vendor") }}
          </span>
          <span v-else-if="props.column.label === 'date'" class="text-nowrap">
            {{ $t("reviews.date") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("reviews.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
          v-if="props.row.product_id"
        >
          <span
            class="text-nowrap custom-toggle"
            v-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'name'">
            {{ props.row.user.first_name + props.row.user.last_name }}
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'date'">
            {{ $helpers.dateTimeInFormat(props.row.created_at) }}
          </span>
          <span
            class="text-nowrap"
            v-else-if="props.column.field === 'product'"
          >
            {{ props.row.product_id.suffix_name }}
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'vendor'">
            {{ props.row.product_id.vendor.store_name }}
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === 'is_active'"
          >
            <b-form-checkbox
              switch
              :disabled="
                globalPermission['reviews-approval'] &&
                !globalPermission['reviews-approval'].update
              "
              v-model="props.row.is_approved"
              @change="(e) => handleReviewVisibility(e, props.row.id)"
              :value="1"
              :unchecked-value="0"
            />
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'actions' &&
              globalPermission.reviews &&
              (globalPermission.reviews.update ||
                globalPermission.reviews.destroy)
            "
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="
                    globalPermission.reviews && globalPermission.reviews.show
                  "
                  @click="showReview(props.row)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("reviews.show") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission.reviews && globalPermission.reviews.destroy
                  "
                  @click="deleteReview(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <ShowReviewModel
      @update-table="update"
      :text="text"
      :userName="userName"
      :stars="stars"
      :id="id"
    />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import ShowReviewModel from "@/components/reviews/ShowReviewModel/ShowReviewModel.vue";

export default {
  name: "reviewsList",
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    OverlayComponent,
    ShowReviewModel,
  },
  data() {
    return {
      text: null,
      userName: null,
      stars: null,
      id: null,
      isLoading: true,
      overlayLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      brandSlug: null,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
        },
        {
          label: "client",
          field: "name",
        },
        {
          label: "product",
          field: "product",
          sortable: false,
        },
        {
          label: "vendor",
          field: "vendor",
          sortable: false,
        },
        {
          label: "date",
          field: "date",
          sortable: false,
        },
        {
          label: "status",
          field: "is_active",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
    };
  },
  created() {
    if (
      this.globalPermission.reviews &&
      !(
        this.globalPermission.reviews.show ||
        this.globalPermission.reviews.destroy
      )
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.label !== "Actions"
      );
    }
  },
  beforeMount() {
    this.getReviewsList();
  },
  methods: {
    async getReviewsList() {
      try {
        const response = (
          await this.$http.get("admin/reviews", {
            headers: {
              "X-Page-Size": 10,
            },
          })
        ).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          (el.index = index + 1), (el.is_approved = +el.is_approved);
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteReview(reviewId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handledeleteReview(reviewId),
          "The_review_has_been_successfully_deleted"
        );
        this.overlayLoading = true;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handledeleteReview(reviewId) {
      this.$http
        .delete(`admin/reviews/${reviewId}`)
        .then((res) => {
          if (res.status === 200) {
            this.getReviewsList();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    async handleReviewVisibility(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_approved: checked,
        };
        const res = await this.$http.post(
          `admin/reviews/${id}?_method=put`,
          data
        );
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch {
        this.$helpers.makeToast(
          "danger",
          this.$t("reviews.status_error_title"),
          this.$t("reviews.status_error_body")
        );
      } finally {
        this.overlayLoading = false;
      }
    },
    showReview(data) {
      this.text = data.text;
      this.userName = data.user.first_name + " " + data.user.last_name;
      this.stars = data.star;
      this.id = data.id;
      this.$bvModal.show("show-review");
    },
    update() {
      this.getReviewsList();
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
